"use client"

import * as Sentry from "@sentry/nextjs"
// import NextError from "next/error"
import { useEffect } from "react"
import NotFound from "./not-found"

// nextjs.org/docs/app/building-your-application/routing/error-handling
export default function GlobalError({
	error,
}: {
	error: Error & { digest?: string }
}) {
	useEffect(() => {
		Sentry.captureException(error)
	}, [error])

	return (
		<NotFound
			heading="Uh Oh! Something went wrong!"
			subHeading={
				"Please refresh the page or go to homepage. If problem persists, please contact support."
			}
			hideButton={false}
			hideStatus
		/>
	)

	// Default Error UI
	// return (
	// 	<html>
	// 		<body>
	// 			{/* `NextError` is the default Next.js error page component. Its type
	//       definition requires a `statusCode` prop. However, since the App Router
	//       does not expose status codes for errors, we simply pass 0 to render a
	//       generic error message. */}
	// 			<NextError statusCode={0} />
	// 		</body>
	// 	</html>
	// )
}
