import { Button } from "@/client/components/atoms/ui/button"
import Link from "next/link"
// import Logo from "@/../public/svgs/logo.svg"
// import Arrow from "@/../public/svgs/arrow.svg"
import React from "react"
import { MoveRight } from "lucide-react"
import { cn } from "@/client/lib/utils"

interface NotFoundProps {
	status?: string
	heading?: string
	subHeading?: string
	hideButton?: boolean
	button?: {
		text: string
		link?: string
		onClick?: () => void
	}
	hideStatus?: boolean
	align?: "center" | "left"
}

const NotFound: React.FC<NotFoundProps> = ({
	status = "404",
	heading = "It looks like that the page is moved or never existed.",
	subHeading = "",
	hideButton = false,
	button = { text: "Return to Home", link: "/" },
	hideStatus = false,
	align = "center",
}) => {
	return (
		<>
			<div className="flex flex-col items-center p-[30px] gap-[24%] h-[100vh]">
				{/* <div className="flex gap-5 items-center">
					<Logo />
					<span className="text-neural-500 text-2xl font-bold tracking-[-0.6px]">
						StockInsights
					</span>
				</div> */}
				<div className="flex flex-col gap-10 mx-auto">
					{!hideStatus && status && (
						<div className="flex mx-auto tracking-[-1.14px]">
							<span className="text-[100px] leading-[100px]">{status}</span>
						</div>
					)}
					<div className="flex flex-col gap-2.5">
						<div
							className={cn(
								"text-neural-500 text-xl tracking-[-0.6px] mx-auto",
								align === "center" && "text-center",
								align === "left" && "text-left"
							)}>
							{heading}
						</div>
						<div
							className={cn(
								"text-sm text-neutral-300 leading-normal font-medium flex mx-auto",
								align === "center" && "text-center",
								align === "left" && "text-left"
							)}>
							{subHeading}
						</div>
					</div>
					{!hideButton && (
						<div
							className={cn(
								"flex",
								align === "center" && "justify-center",
								align === "left" && "justify-start"
							)}>
							{button.link ? (
								<Link href={button.link}>
									<Button
										type="submit"
										variant="onboarding"
										className="flex max-h-[34px] gap-2.5 group">
										<span className="font-normal">{button.text}</span>
										<MoveRight
											width={15}
											height={20}
											className="group-hover:translate-x-1 transition"
										/>
									</Button>
								</Link>
							) : (
								<Button
									type="submit"
									variant="onboarding"
									onClick={button.onClick}
									className="flex max-h-[34px] gap-2.5 group">
									<span className="font-normal">{button.text}</span>
									<MoveRight
										width={15}
										height={20}
										className="group-hover:translate-x-1 transition"
									/>
								</Button>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default NotFound
